import * as React from 'react';

const NavigationHomeActive = props => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.68629 0 0 2.68629 0 6V32C0 35.3137 2.68629 38 6 38H32C35.3137 38 38 35.3137 38 32V6C38 2.68629 35.3137 0 32 0H6ZM29.5 16.5555C30.7833 16.5555 31.8333 15.4555 31.8333 14.1111C31.8333 12.7666 30.7833 11.6666 29.5 11.6666C28.2166 11.6666 27.1666 12.7666 27.1666 14.1111C27.1666 14.3311 27.19 14.5389 27.2483 14.7344L23.1066 19.0855C22.92 19.0244 22.71 19 22.5 19C22.29 19 22.08 19.0244 21.8933 19.0855L18.9183 15.9689C18.9766 15.7733 19 15.5533 19 15.3333C19 13.9889 17.95 12.8889 16.6666 12.8889C15.3833 12.8889 14.3333 13.9889 14.3333 15.3333C14.3333 15.5533 14.3566 15.7733 14.415 15.9689L9.09498 21.53C8.90831 21.4689 8.70998 21.4444 8.49998 21.4444C7.21664 21.4444 6.16664 22.5444 6.16664 23.8889C6.16664 25.2333 7.21664 26.3333 8.49998 26.3333C9.78331 26.3333 10.8333 25.2333 10.8333 23.8889C10.8333 23.6689 10.81 23.4611 10.7516 23.2655L16.06 17.6922C16.2466 17.7533 16.4566 17.7778 16.6666 17.7778C16.8766 17.7778 17.0866 17.7533 17.2733 17.6922L20.2483 20.8089C20.19 21.0044 20.1666 21.2244 20.1666 21.4444C20.1666 22.7889 21.2166 23.8889 22.5 23.8889C23.7833 23.8889 24.8333 22.7889 24.8333 21.4444C24.8333 21.2244 24.81 21.0044 24.7516 20.8089L28.905 16.47C29.0916 16.5311 29.29 16.5555 29.5 16.5555Z"
      fill="#897AA8"
    />
  </svg>
);

export default NavigationHomeActive;
