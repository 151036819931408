import TransactionsList from 'components/TransactionsList/TransactionsList';
import React from 'react';

const DashboardPage = () => {
  return (
    <div>
      <TransactionsList />
    </div>
  );
};

export default DashboardPage;
